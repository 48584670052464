import React, {useEffect, useState} from 'react'
import { getEvents } from "../Home/apis";
import moment from 'moment';
import { Link } from 'react-router-dom';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;


export default function LatestEventsSidebar(props) {

    const [events, setevents] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                const resAnn = await getEvents(orgId, "deenweb")
                const resData = resAnn?.data?.body?.data ?? [];
                setevents(resData)
    
              } catch (err) {
                console.log(err)
              }
        }
        getData();
    }, [])

        const eventsData = events?.length ? events.slice(0, 2).map(({event}, i) => {
        let formatDate = moment(event?.eventStart?.startAt).format("DD MMM");
        let startDateTime = moment(event?.eventStart?.startAt);
        let endDateTime = moment(event?.endAt);
        let formatTime = startDateTime.format("hh:mm a");
        let formatEndTime = endDateTime.format("hh:mm a");
        formatDate = formatDate?.split(/(?<=^\S+)\s/);

        return <Link to={{ 
                    pathname: `/event-details/${event.title.toLowerCase().replace(/ /g, "-")}`,
                    state: { event: event, news: props.news } 
                }}> 
                    <div key={i} className="upc_event" style = {{cursor: "pointer"}}>
                        <div className="upc_e_date"><span>{formatDate[0]}</span><span>{formatDate[1]}</span></div>
                        <div className="upc_e_detail">
                            <h4>{event.title}</h4>
                            <div className="upcomingEventInfo">{formatTime} to {formatEndTime}</div>
                        </div>
                    </div>
                </Link>
    }) : null;

    return (
        <div className="sidebar_section">
            <div className="sidebar_title">
                <h2>UPCOMING EVENTS</h2>
            </div>

            {eventsData}
            {/* <div className="upc_event">
                <div className="upc_e_date"><span>{formatDate[0]}</span><span>{formatDate[1]}</span></div>
                <div className="upc_e_detail">
                    <h4>Importance of Prayer in Islam</h4>
                    <p>7:30 am to 10:30 am</p>
                </div>
                <div className="clearfix"></div>
            </div>

            <div className="upc_event">
                <div className="upc_e_date"><span>21</span><span>OCT</span></div>
                <div className="upc_e_detail">
                    <h4>Importance of Prayer in Islam</h4>
                    <p>7:30 am to 10:30 am</p>
                </div>
                <div className="clearfix"></div>
            </div> */}

        </div>
    )
}
