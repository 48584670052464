import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import SuccessAlert from '../Common/SuccessAlert';
import { PhoneInputField } from "master-react-lib";
import { getOrganization } from '../Home/apis';


const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
class LetsConnectForm extends React.Component {
    constructor(props) {
        super(props);
        this.sectionRef = React.createRef();
        this.state = {
            inquiryTypes: [],
            captcha: '',
            phoneError: false,
            selectedInquiries : [],
            contactData: {
                name: '',
                email: '',
                inquiryType: '',
                message: '',
                stream: process.env.REACT_APP_CONTACT_STREAM,
                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                section: "",
                phone : ''
            },
            showThankYou: false,
            open: false
        }
    }

    async componentDidMount() {
        if(this.props.scrollTo === "connect-form") {
            this.sectionRef.current.scrollIntoView({ behavior: "smooth" });
        }

        console.log('Org Data Called')
        const orgId = process.env.REACT_APP_ORGANIZATION_ID;
        //const token = localStorage.getItem("authToken");
        try {
          const response = await getOrganization(orgId);
          const data = response.data.body.data;
          console.log("response: ", response)
          const pagesAll = data?.enabledIntegrations?.deenweb?.pages;
          const webContactInfo = data?.enabledIntegrations?.deenweb?.settings?.contactDetails ?? {};
            
          let inquiryTypesData = [];
          let a = data?.contactInfo?.forEach(contact => {
            let b = contact?.inquiryTypes?.forEach(inq => {
              inquiryTypesData.push(inq)
            });
          });
          
          const data2 = inquiryTypesData?.length ? inquiryTypesData?.map((el) => { return { id: el._id, primary: el.inquiryName, data: { ...el } } }) : [];
          //console.log("data2: ", data2)
    
          const Ids = webContactInfo?.inquiryTypes.length ? webContactInfo?.inquiryTypes.map(t => t._id) : [];
          //console.log("Ids: ", Ids)
    
         const selectedInq = data2.filter(inq => Ids.includes(inq.id))
         
         this.state.selectedInquiries = selectedInq;
         //console.log("selectedInq: ", this.state.selectedInquiries)
        }
        catch (err) {
            // setLoader(false);
            // seterror(err.response && err.response.data.message)
            // setTimeout(() => {
            //   seterror(false)
            // }, 8000);
          }
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${TEST_SITE_KEY}`
        document.body.appendChild(script)
    }

    setPhone = (phone) => {
        this.setState(prevState => ({
            contactData: {
                ...prevState.contactData,
                phone: phone
            }
        }));
    }

    changeHandler = (e) => {
        console.log("contactData: ", this.state.contactData)
        if (e.target.name === "message" && e.target.value.length > 300) {
            return;
        } else {
            e.persist();
            this.setState(prevState => ({
                contactData: {
                    ...prevState.contactData,
                    [e.target.name]: e.target.value
                }
            }));
        }
    }


    // submitHandler = (e) => {
    //     e.preventDefault();
    //     window.grecaptcha.ready(() => {
    //         window.grecaptcha.execute(TEST_SITE_KEY, { action: 'submit' }).then(token => {
    //           console.log("token", token)
    //           if(token) {
    //             let { name, message, inquiryType, email, phone } = this.state.contactData;
    //             if(phone.length > 3) {
    //                 const validatePhone = phoneRegex.test(phone);
    //                 if(!validatePhone) {
    //                     this.setState({phoneError: true});
    //                 } else {
    //                     this.setState({phoneError: false});
    //                 }
    //             } else {
    //                 this.setState(prevState => ({
    //                     contactData: {
    //                         ...prevState.contactData,
    //                         phone: ''
    //                     },
    //                     phoneError: false
    //                 }));
    //             }
    //             if (name && message && inquiryType && email && !this.state.phoneError) {
    //                 name = name?.split(/(?<=^\S+)\s/)
    //                 axios
    //                     .post(
    //                         process.env.REACT_APP_BASEURL_DEV_API + '/contact', { 
    //                             ...this.state.contactData, 
    //                             name: {
    //                                 firstName: name[0], lastName: name[1]
    //                             } 
    //                         }
    //                     )
    //                     .then(res => {
    //                         this.props.showAlert(true, email)
    //                         localStorage.setItem("contactMail", email)
    //                         console.log('contact ', res);
    //                         this.setState({
    //                          contactData: {
    //                             name: '',
    //                             email: '',
    //                             inquiryType: '',
    //                             message: '',
    //                             phone: '',
    //                             stream: process.env.REACT_APP_CONTACT_STREAM,
    //                             orgId: process.env.REACT_APP_ORGANIZATION_ID,
    //                         },
    //                         })
    //                     })
    //                     .catch(err => console.log('err', err))
    //             }
    //           }
    //         });
    //       });
    // }
    submitHandler = (e) => {
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(TEST_SITE_KEY, { action: 'submit' }).then(token => {
              console.log("token", token)
              if(token) {
                let { name, message, inquiryType, email, phone } = this.state.contactData;
                if(phone.length > 3) {
                    const validatePhone = phoneRegex.test(phone);
                    if(!validatePhone) {
                        this.setState({phoneError: true});
                    } else {
                        this.setState({phoneError: false});
                    }
                } else {
                    this.setState(prevState => ({
                        contactData: {
                            ...prevState.contactData,
                            phone: ''
                        },
                        phoneError: false
                    }));
                }
                if (name && message && inquiryType && email && !this.state.phoneError) {
                    console.log("API CALLED....")
                    name = name?.split(/(?<=^\S+)\s/)
                    axios
                        .post(
                            process.env.REACT_APP_BASEURL_DEV_API + '/contact', { 
                                ...this.state.contactData, 
                                name: {
                                    firstName: name[0], lastName: name[1]
                                } 
                            }
                        )
                        .then(res => {
                            this.props.showAlert(true, email)
                            localStorage.setItem("contactMail", email)
                            console.log('contact ', res);
                            document.body.scrollIntoView();
                            this.setState({
                             contactData: {
                                name: '',
                                email: '',
                                inquiryType: '',
                                message: '',
                                phone: '',
                                stream: process.env.REACT_APP_CONTACT_STREAM,
                                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                            },
                            })
                        })
                        .catch(err => console.log('err', err))
                }
              }
            });
          });
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    render(){
        const inqTypes = this.state.selectedInquiries //this.props.organization?.enabledIntegrations?.deenweb?.settings?.contactDetails?.inquiryTypes ?? []
        console.log("inqTypes: ", inqTypes)
        const { contactData } = this.state;
        const { name, message, inquiryType, email } = contactData;
        let disableBtn = name && message && inquiryType && this.validateEmail(email) ? false : true;

        return (
            <div className="section" ref={this.sectionRef}>


                <div className="container">

                    <div className="connect_form">

                        <div className="sec_title_group_2">
                            <h2 className="section_title">LET'S CONNECT</h2>
                            <p>We will communicate with you and looking forward to hearing from you</p>
                        </div>

                        <form className="gv_form">

                            <div className="gv_form_layout">

                                <div className="row">
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form-group input-group bmd-form-group">
                                            <span className="border-lable-flt">
                                              <input type="text" className="form-control" id="label-name" placeholder="Enter your full name"
                                              name="name"
                                              onChange={this.changeHandler}
                                              value={contactData.name}
                                              required />
                                              <label htmlFor="label-name">Full Name</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                    <div className="form-group input-group bmd-form-group">
                                    <span className="border-lable-flt">
                                    <PhoneInputField
                                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                        country={'us'}
                                        value={this.state?.contactData?.phone}
                                        countryCodeEditable={false}
                                        onChange={(phone, country) => this.setPhone(phone)}
                                        labelFixed="Phone Number (Optional)"
                                        errorText={"Phone number is correct"}
                                        error={this.state.phoneError}
                                    />
                                    </span>
                                    </div>
                                    </div>
                                    
                                    
                                </div>

                                <div className="row">
                                    
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form-group input-group bmd-form-group">
                                            <select className="browser-default custom-select " 
                                            defaultValue={'select'}
                                            name="inquiryType"
                                            onChange={this.changeHandler}
                                            value={contactData.inquiryType}
                                            >
                                                <option value='' selected>Inquiry Types</option>
                                                {inqTypes?.map((el, i) => {
                                                    
                                                    return <option key={i} value={el.data.inquiryName}>{el.data.inquiryName}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6">
                                        <div className="form-group input-group bmd-form-group">
                                            <span className="border-lable-flt">
                                              <input type="text" className="form-control" id="label-name1" placeholder="Enter your Email Address"
                                                name="email"
                                                onChange={this.changeHandler}
                                                value={contactData.email}
                                                required />
                                              <label htmlFor="label-name1">Email Address</label>
                                            </span>
                                        </div>
                                    </div>


                                </div>

                                <div className="row">

                                    <div className="col-md-12 col-lg-12">
                                    <div className="form-group input-group bmd-form-group">
                                            <span className="border-lable-flt">
                                              <textarea className="text_area form-control" maxLength="300" minLength="40" required placeholder="Write Your Message.."   name="message" onChange={this.changeHandler} value={contactData.message}></textarea>
                                              <label htmlFor="label-name">Write Your Message....</label>
                                            </span>
                                            <p className="text_limit_hint">CHAR {contactData?.message?.length ?? 0}/300</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    {/* <div className="form-check no_robot_white">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" defaultValue="" />
                                                I’m not a robot <span className="form-check-sign">
                                            <span className="check"></span> </span>
                                        </label>
                                    </div> */}

                                </div>

                                <div className="btn_send">
                                    <button className={`btn_submit buttonFilled ${disableBtn ? "disabled" : ""}`} onClick={this.submitHandler} disabled={disableBtn}>SEND MESSAGE</button>
                                </div>


                            </div>

                        </form>
                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(LetsConnectForm);