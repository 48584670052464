import React, {useState, useEffect} from 'react';
import BreadCrumbSection from '../BreadCrumbSection/BreadCrumbSection';
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Socialicons from "../Socialicons/Socialicons";
import RecentNews from "../RecentNews/RecentNews";
import Helmet from "react-helmet";
import moment from "moment";
import { serialize } from "../../utils/markdowntoreact";
import { getEvents, getNews, getOrganization } from "../Home/apis";
import Ad336X280 from '../Ad336X280/Ad336X280'
import Ad728X90 from '../Ad728X90/Ad728X90';
import { NoAvailableEvents } from 'master-react-lib';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function EventList(props) {
    const [events, setevents] = useState([])
    const [news, setnews] = useState([])
    const [showFeaturePages, setShowFeaturePages] = useState({});
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            const ordData = await getOrganization(orgId);
            const orgSocials = ordData.data?.body?.data?.orgSocials
            setFacebookurl(orgSocials.facebook);
            seYoutubeUrl(orgSocials.youtube)

            if(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages?.events?.status === "inactive") {
                props.history.push("/");
            }
            setShowFeaturePages(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages);
            if(props?.location?.state?.events) {
                setevents(props?.location?.state?.events)
            } else {
                try {
                    let resEvents = await getEvents(orgId, "deenweb");
                    resEvents = resEvents?.data?.body?.data ?? [];
                    resEvents = resEvents.filter(({status, publishTo, publishFrom}) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                    setevents(resEvents);
                  } catch (err) {
                  }
    
                try {
                    let resNews = await getNews(orgId, "deenweb")
                    resNews = resNews?.data?.body?.data ?? [];
                    setnews(resNews)
        
                } catch (err) {
                }
            };
    
        }
        getData();
    }, [])

    const eventsData = events?.length ? events.map(({event}, i) => {     
        let startDateTime = moment(event?.eventStart?.startAt);
        let endDateTime = moment(event?.endAt);
        let currentDateTime = moment();
        let formatDate = startDateTime.format("DD MMMM, YYYY");
        let formatEndDate = endDateTime.format("DD MMMM, YYYY");
        let image = event?.imageDetails?.imageUrl;
        if(formatDate === formatEndDate) {
            formatEndDate = null;
        }
        let timeDiff = startDateTime.diff(currentDateTime,'minutes'); 
        
        formatDate = formatDate?.split(/(?<=^\S+)\s/);
        let formatTime = startDateTime.format("hh:mm a");
        let formatEndTime = endDateTime.format("hh:mm a");
        
        let x = event?.description && JSON.parse(event?.description);
        let editor;
        if(x) {
         editor = { children: [ ...x ] }
        }

        const description = event?.description && event?.description?.includes('"type":') ? serialize(editor) : event?.description;

        

        return <div className="event_card border">
            <div className="event_card_left">
                {
                    image ? (
                        <>
                            <div className="alfaBG"></div> 
                            <img className="card_event" src={event?.imageDetails?.imageUrl ?? ""} />
                         </>
                        ) 
                        : 
                        <div className="alfaBG" style= {{backgroundColor: '#EFF2F5'}}></div> 
                }
                {(currentDateTime < startDateTime) && (
                    <div className="evnt_date_countdown">
                            <span className="cl_fill">{Math.floor(timeDiff / 1440)}</span>
                            <span>D</span>
                            <span className="cl_fill">{Math.floor((timeDiff % 1440) / 60) }</span>
                            <span>H</span>
                            <span className="cl_fill">{Math.floor((timeDiff % 1440) % 60)}</span>
                            <span>M</span>
                        </div>
                )}
            </div>

                <div className="card_details">
                    <div className="event_date">
                        <div className="day_large">{formatDate?.[0] ?? ""}</div>
                        <div className="ev_date_small">
                            <div className="date_tp">{formatDate?.[1] ? formatEndDate ? `${formatDate?.[1]}  to   ${formatEndDate}`: formatDate?.[1] :   ""}</div>
                            <div className="time_btm">{formatTime} to {formatEndTime} </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    <h4>{event?.title ?? ""}</h4>
                    <p style = {{display: '-webkit-box',
                            webkitLineClamp: '2',
                            webkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'}} className="EventListDescriptions" dangerouslySetInnerHTML={{ __html: description }}></p>

                    <Link className="read_more" to={{ 
                                pathname: `/event-details/${event.title.toLowerCase().replace(/ /g, "-")}`, 
                                state: { 
                                    event: event ,
                                    news: showFeaturePages?.articles?.enablePage ? news : false,
                                    showServices: showFeaturePages?.services?.enablePage,
                                } 
                        }}>VIEW DETAILS <ArrowRightAltIcon/>
                    </Link>

                </div>
                <div className="clearfix"></div>
            </div>

    }) : null;
    
    const eventListProps = {
        facebook : {facebookUrl},
        youtube: {youtubeUrl}
    }
    return (
        <div>

        <div className="fixed_bg"></div>
<               div className="wrapper body">
            {/*------- Update website title -------*/}
            <Helmet>
                <title>Darul Jannah - Events | Powered by DEENCONNECT</title>
            </Helmet>
            {/*-------------- BREADCRUMB SECTION ----------------*/}
            <BreadCrumbSection title="EVENT LIST" b_link="EVENTS"/>

            {/*-------------- EVENTS AND RECENT NEWS ------------*/}
            <div className="section">
                <div className="container">
                    <div className="news_content">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">

                                {events.length > 0 ? 
                                    <React.Fragment>
                                        <div className="sec_title_group">
                                            <h1 className="section_title">UPCOMING DARUL JANNAH EVENTS</h1>
                                        </div>
                                        {eventsData}
                                    </React.Fragment>
                                   
                                : <NoAvailableEvents 
                                        Title="Oops! No available events"
                                        SubTitle="Any available events will appear here."
                                        Description="In the meantime, our secured donation form is superbly ready as we continuously need your generous donations. Please click on the button below to support us."
                                        onClick={() => {
                                            window.open(localStorage.getItem("donationUrl") ?? '#');
                                            return null;
                                            }
                                        }
                                        
                                    />
                                }
                                
                            </div>


                            <div className="col-lg-4 col-md-12">
                                <div className="sec_title_group">
                                    <h2 className="section_title">RECENT NEWS</h2>
                                </div>

                                {/*--------------------- RECENT NEWS COMPONENT -----------------------*/}
                                {showFeaturePages?.articles?.enablePage && 
                                    <RecentNews news={news}  />
                                }

                                <div className="sidebar_section">
                                        <div className="sidebar_title">
                                            <h2>GET CONNECTED</h2>
                                        </div>
                                        <Socialicons orgSocials = {eventListProps} />
                                    </div>

                                <Ad336X280/>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* <div className="section">
                <Ad728X90/>
            </div> */}

            {/*----------------------- WEEKLY PROGRAM SECTION ------------------ */}
            <HomePageWeeklyProgSection />
            {/*----------------------- Services List ----------------------------*/}
            {showFeaturePages?.services?.enablePage && 
                <ServicesList />
            }

            {/* <div className="section">
                <Ad728X90/>
            </div> */}

            <div className="footer_space"></div>
        </div>
        </div>
    );
}

export default EventList;